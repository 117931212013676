import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import SectionHead from '../components/SectionHead'
import ContentBlocks from '../components/ContentBlocks'
import GoogleMap from '../components/Map'

export const PageTemplate = ({ title, children }) => {
  return (
    <section>
      <SectionHead title={title} />
      <div className="container content">
        {children}
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <div>
      <Helmet title={`${page.title} | Q Contemporary`} />
      <PageTemplate title={page.title} content={page.content}>
        { page.acf.content_blocks_page
          ?  <ContentBlocks content={page.acf.content_blocks_page} />
          : false
        }
        <GoogleMap location={page.acf.map} pin="Test" />
      </PageTemplate>
    </div>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired
}

export default Page

export const pageQuery = graphql`
  query VisitQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        pin
        map {
          __typename
          lat
          lng
        }
        content_blocks_page {
          ... on WordPressAcf_text_block {
            __typename
            width
            text
          }
          ... on WordPressAcf_image_block {
            __typename
            width
            image {
              caption
              acf {
                copyright
              }
              id
              localFile {
                childImageSharp {
                    # Try editing the "maxWidth" value to generate resized images.
                    fluid(maxWidth: 1680) {
                      ...GatsbyImageSharpFluid
                    }
                }
           	  }
            }
          }
          ... on WordPressAcf_gallery_block {
            __typename
            width
            gallery {
              caption
              acf {
                copyright
              }
              alt_text
              id
              localFile {
                id
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1680) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
