import React from 'react'
import GoogleMapReact from 'google-map-react'

const Pin = ({ text }) => <div className="map__pin"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 150"><title>q_pin</title><polygon points="0 0 0 100 50 150 100 100 100 0 0 0"/></svg></div>


class Map extends React.Component {

    render() {
      const MapStyles = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]

      const { location } = this.props

      const latitude = Number(location.lat);
      const longitude = Number(location.lng);
      const mapLocation = {
            center: {
                lat: latitude,
                lng: longitude
            },
            zoom: 17
      };
      return (
        // Important! Always set the container height explicitly
        <div id="map" className="map" style={{ height: '900px', width: '100%', marginTop: '-200px', marginBottom: '100px', paddingTop: '200px'}}>
          <a className="button" target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/search/?api=1&query=' + mapLocation.center.lat + ',' + mapLocation.center.lng}>Open in google maps</a>

          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBvCTPRrGEeTzB2hyOvN7Vady44QFUTdZw' }}
            options={{ styles: MapStyles }}
            defaultCenter={mapLocation.center}
            defaultZoom={mapLocation.zoom}
          >
            <Pin
              lat={mapLocation.center.lat}
              lng={mapLocation.center.lng}
              text={this.props.pin}
            />
          </GoogleMapReact>


        </div>
      );
    }
  }
  
  export default Map;